import { useState, useEffect, useRef } from 'react'
import { Row, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useForm } from "react-hook-form";
import moment from 'moment';

// Custom Component
import SearchInput from "../SearchInput/SearchInput";
import NoReceipt from "./NoReceipt";
import ErrorAlert from '../Alert/ErrorAlert';
import Receipt from '../Receipt/Receipt';

// Images
import { GenerateReceipt } from "../../assets/images/svg";

// API Service
import { allReceiptService, singleReceiptCustomerService } from '../../service/tournament.service';

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const MyReceipt = (props) => {
    const [pdfLoading, setPdfLoading] = useState(false)
    const [pdfContentShow, setPdfContentShow] = useState(false)
    const [receiptList, setReceiptList] = useState([])
    const [tournamentList, setTournamentList] = useState([])
    const [tournamentName, setTournamentName] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [searchTag, setSearchTag] = useState('')
    const [receiptInfo, setReceiptInfo] = useState();
    const [csvData, setCSVData] = useState([]);

    const pdfRef = useRef(null);

    const csvHeaders = [
        { label: 'Receipt number', key: 'receipt_no' },
        { label: 'Date', key: 'date' },
        { label: 'Tournament', key: 'tournament' },
        { label: 'Events', key: 'events' },
        { label: 'Event type', key: 'event_type' },
        { label: 'Players', key: 'players' },
        { label: 'Price', key: 'price' }
    ];

    const { register, watch } = useForm({
        mode: "onBlur",
        defaultValues: {
            tournament: '',
            tournament_lg: ''
        }
    })

    const watchTournament = watch("tournament")
    const watchTournamentLg = watch("tournament_lg")

    /**
        * @function fetchReceiptList
        * @params page
        * @description fetch the list of receipt
    */
    const fetchReceiptList = async (page) => {
        try {
            const result = await allReceiptService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: watchTournament || watchTournamentLg,
                receipt_no: searchTag
            });
            if (result?.data?.status) {
                let temp = []
                result?.data?.data?.tournament_lists?.forEach((item) => {
                    if (Object.values(item?.player_details?.reduce((acc, obj) => ({ ...acc, [obj?.receipt_no]: obj }), {}))?.length > 0) {
                        Object.values(item?.player_details?.reduce((acc, obj) => ({ ...acc, [obj?.receipt_no]: obj }), {}))?.forEach((playerItem) => {
                            temp?.push({
                                receipt_no: playerItem?.receipt_no,
                                date: playerItem?.created_at,
                                tournament: item?.name,
                                tournamentId: item?.id,
                                events: item?.category_type === "TEAM" ? 'Team' : 'Individual',
                                event_type: playerItem?.team_event_type ? playerItem?.team_event_type?.map((eventItem, eventIndex) => `(${convertEventType(eventItem)}${playerItem?.team_event_age_group?.length >= eventIndex && playerItem?.team_event_age_value?.length >= eventIndex &&  playerItem?.team_event_age_group[eventIndex] && playerItem?.team_event_age_value[eventIndex] ? ` (${convertAgeGroup(playerItem?.team_event_age_group[eventIndex], playerItem?.team_event_age_value[eventIndex])})` : ""})`)?.join(', ') : `(${convertEventType(playerItem?.event_type)}${playerItem?.age_group && playerItem?.age_value ? ` (${convertAgeGroup(playerItem?.age_group, playerItem?.age_value)})` : ""})`,
                                players: item?.player_details?.filter(findItem => findItem?.receipt_no === playerItem?.receipt_no)?.length,
                                price: playerItem?.team_event_type ? 'RM' + item?.team_event_price : 'RM' + playerItem?.event_price
                            })
                        })
                    }
                })
                setReceiptList(temp)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchAllReceiptList
        * @params
        * @description fetch all list of receipt
    */
    const fetchAllReceiptList = async () => {
        try {
            const result = await allReceiptService({
                paginate: 0,
                page: '',
                perPage: '',
                keyword: '',
                receipt_no: ''
            });
            if (result?.data?.status) {
                let temp = []
                if (result?.data?.data?.tournament_lists?.length > 0) {
                    setTournamentList([...new Set(result?.data?.data?.tournament_lists?.map(item => item?.name))])
                } else {
                    setTournamentList([])
                }
                result?.data?.data?.tournament_lists?.forEach((item) => {
                    if (Object.values(item?.player_details?.reduce((acc, obj) => ({ ...acc, [obj?.receipt_no]: obj }), {}))?.length > 0) {
                        Object.values(item?.player_details?.reduce((acc, obj) => ({ ...acc, [obj?.receipt_no]: obj }), {}))?.forEach((playerItem) => {
                            temp?.push({
                                receipt_no: playerItem?.receipt_no,
                                date: playerItem?.created_at,
                                tournament: item?.name,
                                tournamentId: item?.id,
                                events: item?.category_type === "TEAM" ? 'Team' : 'Individual',
                                event_type: playerItem?.team_event_type ? playerItem?.team_event_type?.map((eventItem, eventIndex) => `(${convertEventType(eventItem)}${playerItem?.team_event_age_group?.length >= eventIndex && playerItem?.team_event_age_value?.length >= eventIndex &&  playerItem?.team_event_age_group[eventIndex] && playerItem?.team_event_age_value[eventIndex] ? ` (${convertAgeGroup(playerItem?.team_event_age_group[eventIndex], playerItem?.team_event_age_value[eventIndex])})` : ""})`)?.join(', ') : `(${convertEventType(playerItem?.event_type)}${playerItem?.age_group && playerItem?.age_value ? ` (${convertAgeGroup(playerItem?.age_group, playerItem?.age_value)})` : ""})`,
                                players: item?.player_details?.filter(findItem => findItem?.receipt_no === playerItem?.receipt_no)?.length,
                                price: playerItem?.team_event_type ? 'RM' + item?.team_event_price : 'RM' + playerItem?.event_price
                            })
                        })
                    }
                })
                if (temp?.length > 0) {
                    setCSVData(temp?.map(item => {
                        return {
                            receipt_no: item?.receipt_no || '-',
                            date: item?.date || '-',
                            tournament: item?.tournament || '-',
                            events: item?.events || '-',
                            event_type: item?.event_type || '-',
                            players: item?.players || '-',
                            price: item?.price || '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchReceiptList(currentPage)
        // eslint-disable-next-line
    }, [searchTag, watchTournament, watchTournamentLg])

    useEffect(() => {
        fetchAllReceiptList()
        // eslint-disable-next-line
    }, [])

    /**
        * @function handleDownload
        * @params
        * @description used to download receipt
    */
    const handleDownload = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setPdfLoading(true)
            setPdfContentShow(true)
        }, 1000);
        setTimeout(() => {
            const content = pdfRef.current;
            html2canvas(content, {
                useCORS: true,
                allowTaint: true,
                width: content?.offsetWidth,
                height: content?.offsetHeight
            }).then((canvas) => {
                const imgData = canvas.toDataURL('img/png');
                const orientation = content?.offsetWidth >= content?.offsetHeight ? 'l' : 'p'
                const doc = new jsPDF({
                    orientation,
                    unit: 'px'
                })
                doc.internal.pageSize.width = content?.offsetWidth;
                doc.internal.pageSize.height = content?.offsetHeight;
                doc.addImage(imgData, 'PNG', 0, 0, content?.offsetWidth, content?.offsetHeight);
                doc.save(`${tournamentName} reciept.pdf`);
                setPdfLoading(false)
                setPdfContentShow(false)
            })
        }, 2000);
    }

    useEffect(() => {
        if (receiptInfo) {
            handleDownload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiptInfo])

    /**
        * @function handleSingleReceipt
        * @params receipt_no, tournamentId, tournamentName
        * @description get the value of receipt
    */
    const handleSingleReceipt = async (receipt_no, tournamentId, tournamentName) => {
        try {
            const result = await singleReceiptCustomerService({
                receipt_no,
                tournamentId
            });
            if (result?.data?.status) {
                setReceiptInfo(result?.data?.data)
                setTournamentName(tournamentName)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    const columns = [
        {
            name: 'Receipt number',
            selector: (row) => row?.receipt_no ? <span className='fs-14 fw-600'>{row?.receipt_no}</span> : '-',
            sortable: true,
            width: '250px'
        },
        {
            name: 'Date',
            selector: (row) => row?.date ? <span className='fs-14 fw-600'>{moment(row?.date).format("DD/MM/YYYY")}</span> : '-',
            sortable: true
        },
        {
            name: 'Tournament',
            selector: (row) => row?.tournament ? <span className='fs-14 text-secondary'>{row?.tournament}</span> : '-',
            sortable: true,
            width: '250px'
        },
        {
            name: 'Events',
            selector: (row) => row?.events ? <span className='fs-14 text-secondary'>{row?.events}</span> : '-',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Event type',
            selector: (row) => row?.event_type ? <span className='fs-14 text-secondary'>{row?.event_type }</span> : '-',
            sortable: true,
            width: '300px'
        },
        {
            name: 'Players',
            selector: (row) => row?.players ? <span className='fs-14 text-secondary'>{row?.players}</span> : '-',
            sortable: true,
            width: '80px'
        },
        {
            name: 'Price',
            selector: (row) => row?.price ? <span className='fs-14 text-secondary'>{row?.price}</span> : '-',
            sortable: true
        },
        {
            name: 'Generate Receipts',
            selector: (row) => <GenerateReceipt role='button' onClick={() => handleSingleReceipt(row?.receipt_no, row?.tournamentId, row?.tournament)} />,
            sortable: true
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchReceiptList(page.selected)
    }

    /**
         * @function CustomPagination
         * @params
         * @description used for Custom Pagination
         */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    /**
        * @function searchReceipt
        * @params searchTerm
        * @description used to set the value of search tag
    */
    const searchReceipt = (searchTerm) => {
        setSearchTag(searchTerm)
    }

    return <LoadingOverlay
        active={pdfLoading}
        spinner={<Spinner animation="border" />}
    >
        <Row className='my-3'>
            <span className='fs-24 fw-600 me-2'>Receipt</span>
            {receiptList?.length > 0 ?
                <>
                    <div className='d-flex flex-column flex-lg-row align-items-start mb-3'>
                        <div className='d-flex justify-content-between justify-content-lg-start align-items-center mb-3 w-100'>
                            <div className='d-flex align-items-center flex-grow-1'>
                                <span className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded me-5'>{csvData?.length || receiptList?.length || 0} receipts</span>
                            </div>
                            <div className='d-none d-lg-block me-2'>
                                <SearchInput className='receipt-search' placeholder='Search by receipt number' search={searchReceipt} />
                            </div>
                            <Form.Group className='d-none d-lg-block flex-grow-1 me-4'>
                                <Form.Select
                                    {...register("tournament_lg")}
                                >
                                    <option value="">Tournaments</option>
                                    {tournamentList?.length > 0 && tournamentList?.map((item, index) => {
                                        return <option value={item} key={`tournament${index}`}>{item}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <CSVLink className="text-decoration-none" filename={`Player details.csv`} data={csvData} headers={csvHeaders}>
                                <span role='button' className='fs-14 fw-600 text-primary p-2 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                            </CSVLink>
                        </div>
                        <div className='d-flex flex-column d-lg-none align-items-center mb-3 w-100'>
                            <div className='w-100'>
                                <SearchInput placeholder='Search by receipt number' search={searchReceipt} />
                            </div>
                            <Form.Group className='d-lg-none mt-3 w-100'>
                                <Form.Select
                                    {...register("tournament")}
                                >
                                    <option value="">Tournaments</option>
                                    {tournamentList?.length > 0 && tournamentList?.map((item, index) => {
                                        return <option value={item} key={`tournament${index}`}>{item}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <DataTable
                        className='d-none d-lg-block'
                        columns={columns}
                        data={receiptList}
                        pagination
                        paginationServer
                        paginationTotalRows={10}
                        paginationPerPage={10}
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        paginationComponent={CustomPagination}
                        paginationDefaultPage={currentPage + 1}
                    />
                    <div>
                        {receiptList?.map((item, index) => {
                            return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={`receipt${index}`}>
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.receipt_no}</span>
                                    <GenerateReceipt role='button' onClick={() => handleSingleReceipt(item?.receipt_no, item?.tournamentId, item?.tournament)} />
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary'>{moment(item?.date).format("DD/MM/YYYY")}</span>
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary text-capitalize'>{item?.tournament}</span>
                                </div>
                                <div className='d-flex align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-secondary me-1'>{item?.events}</span>
                                    <span className='fs-14 fw-400 text-secondary me-1'>.</span>
                                    <span className='fs-14 fw-400 text-secondary me-1'>{item?.event_type}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center w-100 mt-1'>
                                    <span className='fs-14 fw-400 text-primary me-1'>Player : {item?.players}</span>
                                    <span className='fs-14 fw-400 text-secondary me-1'>{item?.price}</span>
                                </div>
                            </div>
                        })}
                        <ReactPaginate
                            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                <span className='fs-14 fw-600 me-2'>Next</span>
                                <AiOutlineArrowRight className='text-secondary' size={20} />
                            </div>}
                            breakLabel='...'
                            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                                <span className='fs-14 fw-600'>Previous</span>
                            </div>}
                            pageRangeDisplayed={3}
                            forcePage={currentPage}
                            marginPagesDisplayed={3}
                            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                            breakClassName='page-item me-3 d-flex align-items-center'
                            nextClassName='page-item next-item flex-grow-1 text-end'
                            previousClassName='page-item prev-item flex-grow-1 me-3'
                            disabledLinkClassName='btn disabled p-0 border-0'
                            pageCount={totalEntry || 1}
                            onPageChange={page => handlePagination(page)}
                            containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                        />
                    </div>
                    <Receipt pdfRef={pdfRef} pdfContentShow={pdfContentShow} tournament={receiptInfo} />
                </>
                :
                <NoReceipt />
            }
        </Row>
    </LoadingOverlay>
};

export default MyReceipt;